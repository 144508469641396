import React, { isValidElement, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useParams, useNavigate } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import {
  ArrowLeftIcon,
  ShareIcon,
  EyeIcon,
  MagnifyingGlassIcon,
  MapPinIcon,
  BookmarkIcon,
  QuestionMarkCircleIcon,
  Bars3Icon,
  GlobeAltIcon,
} from "@heroicons/react/24/outline"; // Importing icons from Heroicons v2
import { BookmarkIcon as BookmarkIconSolid } from "@heroicons/react/24/solid"; // Solid version
import SplashScreen from "./components/splashscreen";
import "./App.css";
import "tailwindcss/tailwind.css";
import { useMediaQuery } from "react-responsive";
//import DownloadLogos from "./components/DownloadLogos";
import categories from "./resources/categories.json";
import stages from "./resources/stages.json";
import lounges from "./resources/lounges.json";
import gettinghere from "./resources/gettinghere.json";
import StagesComponent from "./components/StagesComponent"; // Import the StagesComponent
import LoungesSelector from "./components/lounges";
import GettingHereTaskBar from "./components/gettinghere";
import SupportTaskBar from "./components/supporttaskbar";
import findBoothPath from "./components/directions/pathfinder"
import FavoriteCard from "./components/favoriteCard";
import CategorySelector from "./components/categoryselector";
import GetDirections from "./components/directions/directions";
import EditCompanyDetailsForm from "./components/EditCompanyDetailsForm";
import { addGraphLayers, getPath } from "./components/graph-test";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { loadExhibitionMap } from "./components/exhibition";
import { useSearchParams } from "react-router-dom"; // For URL query parameters


// Load Threebox
const threeboxScript = document.createElement("script");
threeboxScript.src = "https://cdn.jsdelivr.net/gh/jscastro76/threebox@v.2.2.2/dist/threebox.min.js";
document.head.appendChild(threeboxScript);

mapboxgl.accessToken = "pk.eyJ1IjoicGFua2FqLWd5YW5zaGFrdGkiLCJhIjoiY2x3dnB5ZDJnMG1sbDJycXptYnhiZnowdSJ9.5dGUs5xxc1GeXmn3cDfEYg";

let initialViewState = {};
let flyToViewState = {};
let fitBoundViewState = {};
let isFilteredBoothActive = false;
let isBoothSelected = false;


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MapApp />} />
        <Route path="/:slug" element={<MapApp />} />
      </Routes>
    </Router>
  );
};

const MapApp = () => {
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [booths, setBooths] = useState([]);
  const [passages, setPassages] = useState([]);
  const [filteredBooths, setFilteredBooths] = useState([]);
  const [selectedBooth, setSelectedBooth] = useState(null);
  const [hoveredBoothId, setHoveredBoothId] = useState(null); // For hover effect
  const [bookmarkedBooths, setBookmarkedBooths] = useState([]);
  const markers = useRef([]);
  const [collapsed, setCollapsed] = useState(true);
  const [activeItem, setActiveItem] = useState("Search");
  const [loading, setLoading] = useState(true);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isLandscape = useMediaQuery({ orientation: "landscape" });
  const [selectedSectors, setSelectSectors] = useState(false);
  const [activeTask, setActiveTask] = useState("View All");
  const [visitedBooths, setVisitedBooths] = useState([]);
  const [isCategorySelectorVisible, setIsCategorySelectorVisible] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isAmenitiesVisible, setIsAmenitiesVisible] = useState(false);
  const [isSupportTaskBarVisible, setIsSupportTaskBarVisible] = useState(false);
  const[isStagesVisible, setIsStagesVisible]=useState(false);
  const[isLoungesVisible,setIsLoungesVisible]=useState(false);
  const[isGettingHereVisible,setIsGettingHereVisible]=useState(false);
  let originalCenter = [37.387141132383135,55.82251563180171]; // Original center
  const [passagesData, setPassagesData] = useState(null);
  const [poisData, setPoisData] = useState(null);
  const [pathFound, setPathFound] = useState(false);
  const [pathStart, setPathStart] = useState(null);
  const [pathDestination, setPathDestition] = useState(null);
  const [verticesData, setVerticesData]  = useState(null);
  const [edgesData, setEdgesData]= useState(null);
  const [hall,setHall]=useState([])
  const [exhibitionData, setExhibitionData] = useState(null);
  const [parkingdata, setParkingdata] = useState(null);
  const [maineCoordinates, setMaineCoordinates] = useState(null);
  const [originalBounds, setOriginalBounds] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const name = urlParams.get("file"); // Example: '?file=GITEX'
  const [searchParams] = useSearchParams();
  const isAdminMode = searchParams.get("mode") === "admin";
  const { slug } = useParams(); // Get booth slug from URL
  const [company, setCompany] = useState({
    properties: {
      Name: "Example Company",
      Description: "This is a sample description.",
      image: "example.jpg",
      boothDetails: "A1",
    },
  });

  const adjustCenterForMobile = (originalCenter, bearing) => {
    if (isMobile) {
      const [lng, lat] = originalCenter;

      // Amount to shift - tune this value based on screen size or UI element height
      const shiftAmount = 0.00015;

      // Convert bearing to radians
      const bearingInRadians = (bearing * Math.PI) / 180;

      // Calculate the shift in both latitude and longitude based on bearing
      const newLat = lat - shiftAmount * Math.cos(bearingInRadians); // Shift considering the bearing angle
      const newLng = lng - shiftAmount * Math.sin(bearingInRadians);

      return [newLng, newLat]; // Return the adjusted center considering the map"s bearing
    }
    return originalCenter;
  };
  
  if( isMobile)
    { 
      initialViewState = {
        center: adjustCenterForMobile(originalCenter, 9.0 /**bearing */), // Adjusted center for mobile view
        zoom: 17,
        zoom_max: 25.0,
        zoom_max_desktop: 25.0,
        zoom_fitbound: 19.0,
        zoom_fitbound_desktop: 18.7,
        zoom_min: 10.0,
        zoom_drawing: 21.0,
        bounds: [],
        active_poi_padding: 20.0,
        active_zone_padding: 20.0,
        pitch: 15.0,
        pitch_max: 60.0,
        pitch_min: 0.0,
        bearing: 9.0,
      };
    }
   else if (name === "WELDEX") {
      initialViewState = {
        center: originalCenter,
        zoom: 17.75,
        zoom_max: 25.0,
        zoom_max_desktop: 25.0,
        zoom_fitbound: 19.0,
        zoom_fitbound_desktop: 18.7,
        zoom_min: 10.0,
        zoom_drawing: 21.0,
        bounds: [],
        active_poi_padding: 20.0,
        active_zone_padding: 20.0,
        pitch: 30.0,
        pitch_max: 60.0,
        pitch_min: 0.0,
        bearing: 9.0, // Bearing for Weldex
      };
  } else if (name === "GITEX") {
      initialViewState = {
        center: originalCenter,
        zoom: 16,
        zoom_max: 25.0,
        zoom_max_desktop: 25.0,
        zoom_fitbound: 19.0,
        zoom_fitbound_desktop: 18.7,
        zoom_min: 10.0,
        zoom_drawing: 21.0,
        bounds: [],
        active_poi_padding: 20.0,
        active_zone_padding: 20.0,
        pitch: 30.0,
        pitch_max: 60.0,
        pitch_min: 0.0,
        bearing: 120.0, // Bearing for Gitex
      };
    }
    else{
      initialViewState = {
        center: originalCenter,
        // zoom: 18,
        // pitch: 60,
        // bearing: 123.2,
        zoom: 17.75,
        zoom_max: 25.0,
        zoom_max_desktop: 25.0,
        zoom_fitbound: 19.0,
        zoom_fitbound_desktop: 18.7,
        zoom_min: 10.0,
        zoom_drawing: 21.0,
        bounds: [],
        active_poi_padding: 20.0,
        active_zone_padding: 20.0,
        pitch: 30.0,
        pitch_max: 60.0,
        pitch_min: 0.0,
        bearing: 9.0,
      };
    }
    flyToViewState = initialViewState;
    fitBoundViewState = initialViewState;
    if( isMobile)
      { 
        flyToViewState = {
          center: [ 37.38683417241515, 55.8225372418895], // CROCUS Expo
          // zoom: 18,
          // pitch: 60,
          // bearing: 123.2,
          zoom: 19.25,
          zoom_max: 25.0,
          zoom_max_desktop: 25.0,
          zoom_fitbound: 19.0,
          zoom_fitbound_desktop: 18.7,
          zoom_min: 10.0,
          zoom_drawing: 21.0,
          bounds: [],
          active_poi_padding: 20.0,
          active_zone_padding: 20.0,
          pitch: 30.0,
          pitch_max: 60.0,
          pitch_min: 0.0,
          bearing: 9.0,
        };
        fitBoundViewState  = {
          center: [ 37.38683417241515, 55.8225372418895], // CROCUS Expo
          // zoom: 18,
          // pitch: 60,
          // bearing: 123.2,
          zoom: 20,
          zoom_max: 25.0,
          zoom_max_desktop: 25.0,
          zoom_fitbound: 19.0,
          zoom_fitbound_desktop: 18.7,
          zoom_min: 10.0,
          zoom_drawing: 21.0,
          bounds: [],
          active_poi_padding: 20.0,
          active_zone_padding: 20.0,
          pitch: 30.0,
          pitch_max: 60.0,
          pitch_min: 0.0,
          bearing: 9.0
        };
      }
      else if (name === "WELDEX") {
        flyToViewState = {
          center: [37.38683417241515, 55.8225372418895], // CROCUS Expo
          zoom: 18,
          zoom_max: 25.0,
          zoom_max_desktop: 25.0,
          zoom_fitbound: 19.0,
          zoom_fitbound_desktop: 18.7,
          zoom_min: 10.0,
          zoom_drawing: 21.0,
          bounds: [],
          active_poi_padding: 20.0,
          active_zone_padding: 20.0,
          pitch: 30.0,
          pitch_max: 60.0,
          pitch_min: 0.0,
          bearing: 10, // Bearing for Weldex
        };
      
        fitBoundViewState = {
          center: [37.38683417241515, 55.8225372418895], // CROCUS Expo
          zoom: 15,
          zoom_max: 25.0,
          zoom_max_desktop: 25.0,
          zoom_fitbound: 19.0,
          zoom_fitbound_desktop: 18.7,
          zoom_min: 10.0,
          zoom_drawing: 21.0,
          bounds: [],
          active_poi_padding: 20.0,
          active_zone_padding: 20.0,
          pitch: 30.0,
          pitch_max: 60.0,
          pitch_min: 0.0,
          bearing: 12.0, // Bearing for Weldex
        };
      } else if (name === "GITEX") {
        flyToViewState = {
          center:  [55.288923033489084,25.224085325808346], // CROCUS Expo
          zoom: 18,
          zoom_max: 25.0,
          zoom_max_desktop: 25.0,
          zoom_fitbound: 19.0,
          zoom_fitbound_desktop: 18.7,
          zoom_min: 10.0,
          zoom_drawing: 21.0,
          bounds: [],
          active_poi_padding: 20.0,
          active_zone_padding: 20.0,
          pitch: 30.0,
          pitch_max: 60.0,
          pitch_min: 0.0,
          bearing: 130, // Bearing for Gitex
        };
      
        fitBoundViewState = {
          center: [55.288923033489084,25.224085325808346], // CROCUS Expo
          zoom: 15,
          zoom_max: 25.0,
          zoom_max_desktop: 25.0,
          zoom_fitbound: 19.0,
          zoom_fitbound_desktop: 18.7,
          zoom_min: 10.0,
          zoom_drawing: 21.0,
          bounds: [],
          active_poi_padding: 20.0,
          active_zone_padding: 20.0,
          pitch: 30.0,
          pitch_max: 60.0,
          pitch_min: 0.0,
          bearing: 120.0, // Bearing for Gitex
        };
      } else {
        // Default state in case neither 'weldex' nor 'gitex' is specified
        flyToViewState = {
          center: [37.38683417241515, 55.8225372418895], // CROCUS Expo
          zoom: 18,
          zoom_max: 25.0,
          zoom_max_desktop: 25.0,
          zoom_fitbound: 19.0,
          zoom_fitbound_desktop: 18.7,
          zoom_min: 10.0,
          zoom_drawing: 21.0,
          bounds: [],
          active_poi_padding: 20.0,
          active_zone_padding: 20.0,
          pitch: 30.0,
          pitch_max: 60.0,
          pitch_min: 0.0,
          bearing: 10, // Default bearing
        };
      
        fitBoundViewState = {
          center: [37.38683417241515, 55.8225372418895], // CROCUS Expo
          zoom: 15,
          zoom_max: 25.0,
          zoom_max_desktop: 25.0,
          zoom_fitbound: 19.0,
          zoom_fitbound_desktop: 18.7,
          zoom_min: 10.0,
          zoom_drawing: 21.0,
          bounds: [],
          active_poi_padding: 20.0,
          active_zone_padding: 20.0,
          pitch: 30.0,
          pitch_max: 60.0,
          pitch_min: 0.0,
          bearing: 12.0, // Default bearing
        };
      }
      
    
  const amenities = [
    "View All",
    "Male Washroom",
    "Female Washroom",  
    "Male prayer hall",
    "Female prayer hall",
    "Lounges",
  ];

  const gettinghereTaskBar = [
    "View All",
    "Parkings",
    "Transportation Hub",  
    "Entrances",
  ];
  
  const supportCategory = categories.find(
    (category) => category.name === "Support"
  );
  
  const supportCategories = supportCategory ? supportCategory.children.map(child => child.name) : [];
  
  
// To open the selector
const openCategorySelector = () => {
  setIsCategorySelectorVisible(true);
};

// To close the selector
const closeCategorySelector = () => {
  setIsCategorySelectorVisible(false);
};

  
  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClick = (item) => {
    setActiveItem(item); // Update the active menu item
    
    // Clear the selected company when switching to a different menu
    if (item !== "Bookmarks") {
      setSelectedCompany(null);
      setSelectedBooth(null);
      isBoothSelected = false;
    }

    if (item !== "Directions") {
      if (map.getLayer('route-arrows')) {
        map.removeLayer('route-arrows');
      }
      
      if (map.getLayer('custom-path')) {
        map.removeLayer('custom-path');
      }
      
      if (map.getSource('custom-path')) {
        map.removeSource('custom-path');
      }
    }
  
    if (item === "Bookmarks") {
      setBookmarksFilter(); // Highlight bookmarked booths when "Bookmarks" is selected
      setSelectedCompany(null);
    } else if (item === "Tasks") {
      setActiveTask("View All"); // Default task view
    }
  };

  
  const handleView = (company, map) => {
    isFilteredBoothActive = false;
    isBoothSelected = true;
    setSelectedCompany(company);
    const coordinates = company.geometry.coordinates.slice();
    const { Name, Description, id } = company.properties;
    const { slug } = company.properties;

    if (slug) {
      // Update the URL with the slug
      window.history.pushState({}, "", `/${slug}`);
    }

    flyToCompany(map, coordinates, id);
  };

  const handleBack = () => {
    setSelectedCompany(null);
    isBoothSelected = false;
    window.history.pushState({}, "", "/");
  };
  const setBookmarksFilter = () => {
    const filteredIds = bookmarkedBooths.map((booth) => booth.id);

    if (map && filteredIds.length > 0) {
      isFilteredBoothActive = true;
      // Highlight bookmarked booths (extrude and change color)
      map.setPaintProperty("booths-layer", "fill-extrusion-height", [
        "case",
        ["in", ["get", "id"], ["literal", filteredIds]],
        10, // Extrude bookmarked booths
        1, // Default height for non-bookmarked booths
      ]);

      map.setPaintProperty("booths-layer", "fill-extrusion-color", [
        "case",
        ["in", ["get", "id"], ["literal", filteredIds]],
        "#f48432", // Color for bookmarked booths
        "#cccccc", // Default color for non-bookmarked booths
      ]);

      // Fit the map to show all bookmarked booths
      const bookmarkedBoothCoordinates = bookmarkedBooths.flatMap(
        (booth) => booth.geometry.coordinates[0] || []
      );

      if (bookmarkedBoothCoordinates.length > 0) {
        let fc = convertToLngLat(bookmarkedBoothCoordinates);

        const bounds = fc.reduce(
          (bounds, coord) => bounds.extend(coord),
          new mapboxgl.LngLatBounds(fc[0], fc[1])
        );
        map.fitBounds(bounds, {
          bearing: 9.0,
          pitch: 30.0,
          zoom: isMobile ? 17 : 17.25,
          padding: isMobile
          ? { top: 50, bottom: 20, left: 20, right: 20 }:{ top: 20, bottom: 20, left: 350, right: 20 },
          maxZoom: 25,
        });
      } else {
        console.error("No valid coordinates found for bookmarked booths.");
      }
    } else {
      isFilteredBoothActive = false;
    }
  };

  const convertToLngLat = (coordinates) => {
    // Helper function to flatten the array recursively and filter valid coordinates
    const flattenCoordinates = (arr) => {
      return arr.reduce((acc, val) => {
        // If it's an array, recursively flatten it
        if (Array.isArray(val)) {
          return acc.concat(flattenCoordinates(val));
        }
        return acc.concat(val); // Otherwise, add it to the result
      }, []);
    };

    // Flatten the coordinates array
    const flattened = flattenCoordinates(coordinates);

    // Ensure the flattened array has valid longitude-latitude pairs
    const validCoordinates = [];
    for (let i = 0; i < flattened.length; i += 2) {
      if (!isNaN(flattened[i]) && !isNaN(flattened[i + 1])) {
        validCoordinates.push([flattened[i], flattened[i + 1]]);
      } else {
        throw new Error(
          `Invalid coordinate pair at index ${i}: [${flattened[i]}, ${
            flattened[i + 1]
          }]`
        );
      }
    }

    return validCoordinates;
  };

  const convertToLngLat1 = (coordinates) => {
    // Check if it's an array of coordinates (e.g., polygon or multi-point geometry)
    if (Array.isArray(coordinates)) {
      // Case 1: If it is a polygon or multi-point, extract the first point's coordinates
      if (Array.isArray(coordinates[0])) {
        const [longitude, latitude] = coordinates[0];
        return new mapboxgl.LngLat(longitude, latitude);
      }
  
      // Case 2: If coordinates are valid but not a polygon or multi-point, handle accordingly
      const [longitude, latitude] = coordinates;
      return new mapboxgl.LngLat(longitude, latitude);
    }
  
    // Case 3: Handle invalid input
    throw new Error('Invalid coordinates format');
  };
  
  

  // Function to adjust the map to the booth's coordinates
  const fitMapToBooth = (coordinates) => {
    const lngLat = convertToLngLat1(coordinates);
    const bounds = new mapboxgl.LngLatBounds(lngLat, lngLat);
    map.fitBounds(bounds, {
      bearing: 9.0,
      pitch: 30.0,
      zoom: 17.25,
      padding: isMobile
        ? { top: 10, bottom: 10, left: 20, right: 200 } // Mobile-specific padding
        : { top: 20, bottom: 20, left: 200, right: 20 }, // Default padding for larger screens
      maxZoom: 25,
    });
  };

  const setFilter = (selectedCategories) => {
    if (selectedCategories.length === 0) {
      // Reset to view all booths
      setFilteredBooths(booths);
      isFilteredBoothActive = false;
      
      if (map) {
        // Reset booth layer properties for all booths
        map.setPaintProperty("booths-layer", "fill-extrusion-color", [
          "case",
          ["==", ["get", "group"], "red"], "#FF6347",
          ["==", ["get", "group"], "green"], "#32CD32",
          ["==", ["get", "group"], "purple"], "#9370DB",
          ["==", ["get", "group"], "orange"], "#FFA500",

          ["==", ["get", "group"], "GreenYellow"], "#9ACD32",
          ["==", ["get", "group"], "Coral"], "#FF4500",
          ["==", ["get", "group"], "Gold"], "#FFD700",
        //  ["==", ["%", ["get", "id"], 15], 0], "#FF6F61",
          //["==", ["%", ["get", "id"], 5], 0], "#FFA07A",
         // ["==", ["%", ["get", "id"], 3], 0], "#FFD700",
          "#FFA07A"
        ]);
  
        map.setPaintProperty("booths-layer", "fill-extrusion-height", [
          "case",
          ["==", ["get", "id"], selectedBooth], 4, // Full height for selected booth
          ["==", ["get", "id"], hoveredBoothId], 2, // Half height for hovered booth
          1, // Default height for all other booths
        ]);
  
        resetView(); // Optional: Reset the map view to default
      }
    } else {
      let filtered = [];
      let filteredBoothCoordinates = [];
  
      if (selectedCategories[0].type === "Feature") {
        // Called from search (Feature-based filtering)
        filtered = selectedCategories;
        filteredBoothCoordinates = filtered.flatMap(
          (booth) => booth.geometry.coordinates[0] || []
        );
        const convertedCoordinates = convertToLngLat(filteredBoothCoordinates);
        filteredBoothCoordinates = convertedCoordinates;
      } else {
        // From selected categories (filter by categories)
        filtered = booths.filter((booth) => {
          const categories = Array.isArray(booth.properties.category)
            ? booth.properties.category
            : [booth.properties.category]; // Ensure category is always an array
          return categories.some((category) => selectedCategories.includes(category));
        });
  
        filteredBoothCoordinates = filtered.flatMap(
          (booth) => booth.geometry.coordinates[0] || []
        );
      }
  
      setFilteredBooths(filtered);
      isFilteredBoothActive = true;
  
      const filteredIds = filtered.map((b) => b.id);
  
      // Highlight filtered booths (extrude and change color)
      map.setPaintProperty("booths-layer", "fill-extrusion-height", [
        "case",
        ["in", ["get", "id"], ["literal", filteredIds]],
        10, // Extrude filtered booths
        1, // Default height for non-filtered booths
      ]);
  
      map.setPaintProperty("booths-layer", "fill-extrusion-color", [
        "case",
        ["in", ["get", "id"], ["literal", filteredIds]],
        "#f48432", // Color for filtered booths
        "#cccccc", // Default color for non-filtered booths
      ]);    
  
      if (filteredBoothCoordinates.length > 0) {
        const fc = convertToLngLat(filteredBoothCoordinates);
        const bounds = fc.reduce(
          (bounds, coord) => bounds.extend(coord),
          new mapboxgl.LngLatBounds(fc[0], fc[1])
        );
        map.fitBounds(bounds, {
          bearing: 9.0,
          pitch: 30.0,
          zoom: isMobile ? 17 : 17.25,
          padding: isMobile
          ? { top: 50, bottom: 20, left: 20, right: 20 }:{ top: 20, bottom: 20, left: 300, right: 20 },
          maxZoom: 25,
        });
      } else {
        console.error("No valid coordinates found for bookmarked booths.");
      }
    }
  };
  

  const saveBookmarksToLocalStorage = (bookmarks) => {
    localStorage.setItem("bookmarks", JSON.stringify(bookmarks));
  };
  const toggleBookmark = (booth) => {
    setBookmarkedBooths((prevBookmarkedBooths) => {
      const updatedBookmarks = prevBookmarkedBooths.some(
        (b) => b.id === booth.id
      )
        ? prevBookmarkedBooths.filter((b) => b.id !== booth.id)
        : [...prevBookmarkedBooths, booth];

      // Save the updated bookmarks to local storage
      saveBookmarksToLocalStorage(updatedBookmarks);
      return updatedBookmarks;
    });
  };

  const setActiveAmenity = (amenity) => {
    if (amenity === "View all") {
      resetView(); // Reset map to show all amenities
    } else {
      // Add logic to filter map elements based on the selected amenity
    }
  };
  const setActiveSupport = (support) => {
    if (support === "View all") {
      resetView(); // Reset map to show all amenities
    } else {
      // Add logic to filter map elements based on the selected amenity
    }
  };

  const setActiveGettingHere = (gettinghere) => {
    if (gettinghere === "View all") {
      resetView(); 
    } else {
      // Add logic to filter map elements based on the selected amenity
    }
  };
  
const saveVisitedBoothsToLocalStorage = (visitedBooths) => {
  localStorage.setItem("visitedBooths", JSON.stringify(visitedBooths));
};
  const toggleVisited = (boothId) => {
    setVisitedBooths((prevVisitedBooths) => {
      const isVisited = prevVisitedBooths.includes(boothId);
      const updatedVisitedBooths = isVisited
        ? prevVisitedBooths.filter((id) => id !== boothId)
        : [...prevVisitedBooths, boothId];

      // Save the updated visited booths to local storage
      saveVisitedBoothsToLocalStorage(updatedVisitedBooths);
      return updatedVisitedBooths;
    });
  };

  // Check URL for slug and focus on the booth if present
  const handleSlugChange = (slug) => {
    const booth = booths.find((booth) => booth.properties.slug === slug);
    if (booth) {
      const coordinates = booth.geometry.coordinates.slice();
      flyToCompany(map, coordinates, booth.id);
      setSelectedCompany(booth);
    }
  };

  useEffect(() => {
    // Simulate a loading time of 3 seconds
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // 3000ms = 3 seconds

    // Load bookmarks from localStorage
    const savedBookmarks = localStorage.getItem("bookmarks");
    if (savedBookmarks) {
      setBookmarkedBooths(JSON.parse(savedBookmarks));
    }

    // Load visited booths from localStorage
    const savedVisitedBooths = localStorage.getItem("visitedBooths");
    if (savedVisitedBooths) {
      setVisitedBooths(JSON.parse(savedVisitedBooths));
    }

    // Handle Escape key press to close popups
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        // Close all popups and reset views
        setSelectedCompany(null);
        setActiveItem("Search");
        setActiveTask("View All");
        //resetView();
      }
    };

    // Add the Escape key event listener
    window.addEventListener("keydown", handleKeyDown);

    // Clean up the timer and event listener when the component unmounts
    return () => {
      clearTimeout(timer);
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (map && !isFilteredBoothActive) {
      if (selectedBooth === undefined || selectedBooth === null)
        setSelectedBooth(0);
      if (hoveredBoothId === undefined || hoveredBoothId === null)
        setHoveredBoothId(0);
      map.setPaintProperty("booths-layer", "fill-extrusion-height", [
        "case",
        ["==", ["get", "id"], selectedBooth], // Full height for selected booth
        5,
        ["==", ["get", "id"], hoveredBoothId], // Half height for hovered booth
        3,
        0, // Default height
      ]);
      if(isBoothSelected){
      map.setPaintProperty("booths-layer", "fill-extrusion-color", [
        "case",
        ["==", ["get", "id"], selectedBooth],
        "#f48432", // Color for bookmarked booths
        "#cccccc", // Default color for non-bookmarked booths
      ]);}
    }
  }, [map,hoveredBoothId,selectedBooth]);

  useEffect(() => {
      if (!loading && mapContainer.current) {  // Ensure mapContainer is available and loading is done
        loadExhibitionMap(name)
        .then(({ exhibitionData,maineCoordinates,exhibitiontreeData,borderData,towerdata,parkingdata}) => {  // Updated variables to match new structure
          if (exhibitionData) {
            setExhibitionData(exhibitionData); // Store it in state
          }
          if (parkingdata) {
            setParkingdata(parkingdata); // Store it in state
          }
          if (maineCoordinates) {
            setMaineCoordinates(maineCoordinates); // Store it in state
          }
          // Check if the coordinates are valid before using them
          if (maineCoordinates && !isNaN(maineCoordinates[0]) && !isNaN(maineCoordinates[1])) {
            
            flyToViewState.center=fitBoundViewState.center=initialViewState.center=maineCoordinates;
            
           } else {
             console.error("Invalid coordinates:", maineCoordinates); // If invalid coordinates
           }

      const initializeMap = ({ setMap, mapContainer }) => {
        const map = new mapboxgl.Map({
          container: mapContainer.current,
          style: "mapbox://styles/mapbox/light-v11",
          attributionControl: false,
          language: "en",
          touchZoomRotate: true,
          trackResize: true,
          center: initialViewState.center,
          zoom: initialViewState.zoom,
          bearing: initialViewState.bearing,
          antialias: true,
//          pitch: initialViewState.pitch,
          pitch: 30,
          pitch_max: 60,
          pitch_min: 0,
          zoom_drawing: 21,
          zoom_fitbound: 19,
          zoom_fitbound_desktop: 18.7,
          zoom_max: 25,
          zoom_max_desktop: 25,
          zoom_min: 10,
          scrollZoom: true, // Enable smooth scroll zoom
          boxZoom: true, // Enable zoom with box selection
          dragPan: true, // Enable drag panning
        });
        map.scrollZoom.setWheelZoomRate(0.5); // Adjust zoom speed (lower is smoother)
        map.attributionControl = false;
        map.language = "en";

        map.on("load", async () => {
          // Add zoom and rotation controls to the map.
          map.addControl(new mapboxgl.NavigationControl({
            visualizePitch: true
          }), isDesktop ? "bottom-right" : "top-right");

          // Add custom reset view control
          const ResetControl = () => {
            const control = document.createElement("button");
            control.className = "mapboxgl-ctrl-icon mapboxgl-ctrl-reset";
            control.type = "button";
            control.title = "Reset View";
            control.innerHTML = "⟳"; // Use an icon or text
            control.onclick = () => {
              map.flyTo({
                center: initialViewState.center,
                zoom: initialViewState.zoom,
                pitch: initialViewState.pitch,
                bearing: initialViewState.bearing,
                essential: true,
              });
            };
            return control;
          };

          class ResetViewControl {
            onAdd(map) {
              this.map = map;
              this.container = document.createElement("div");
              this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";
              this.container.appendChild(ResetControl());
              return this.container;
            }

            onRemove() {
              this.container.parentNode.removeChild(this.container);
              this.map = undefined;
            }
          }
          setOriginalBounds(map.getBounds()); 

          map.addControl(new ResetViewControl(), "bottom-right"); 

          // Load the GeoJSON for passages and booth locations

          // render layout
         
                const stands = exhibitionData.features.filter(feature => feature.properties.type !== "stand");
                const passages = exhibitionData.features.filter(feature => feature.properties.type === "passage");
                
               
                setBooths(stands);
                setPassages(passages);
                //setHall(hallborder)

              map.addSource("booths", {
                type: "geojson",
                data: {
                  type: "FeatureCollection",
                  features: stands,
                },
              });
             

              map.addSource("borderData", {
                type: "geojson",
                data:borderData
             });

             const lighterGroupColors = {
              red: "#FF7F7F",    // Lighter Tomato
              green: "#90EE90",  // Light Green
              purple: "#DDA0DD", // Plum
              orange: "#FFDAB9", // Peach Puff
              GreenYellow: "#ADFF2F", // Honeydew
              Coral: "#FFA07A",  // Light Pink
              Gold: "#FFA500",   // Lemon Chiffon
             };
              
              map.addLayer({
                id: "booths-layer",
                type: "fill-extrusion",
                source: "booths",
                paint: {
                   
                    "fill-extrusion-color": [
                        "case",
                        // Check for extrusion color based on "group" property first
                        ["==", ["get", "group"], "red"], "#FF6347",    // Tomato color for red group extrusion
                        ["==", ["get", "group"], "green"], "#32CD32",  // Lime green for green group extrusion
                        ["==", ["get", "group"], "purple"], "#9370DB", // Medium purple for purple group extrusion
                        ["==", ["get", "group"], "orange"], "#FFA500", // orange group color

                        ["==", ["get", "group"], "GreenYellow"], "#9ACD32",
                        ["==", ["get", "group"], "Coral"], "#FF4500",
                        ["==", ["get", "group"], "Gold"], "#FFD700",
            
                        // If no "group" matches, fallback to ID-based rules
                        // ["==", ["%", ["get", "id"], 11], 0], "#FF6F61", // Color 1 (e.g., Red)
                        // ["==", ["%", ["get", "id"], 7], 0], "#FFA07A",  // Color 2 (e.g., Light Coral)
                        // ["==", ["%", ["get", "id"], 3], 0], "#FFD700",  // Color 3 (e.g., Gold)
            
                        // Default color if neither group nor ID-based rules match
                        "#ADFF2F" // GreenYellow
                    ],
            
                    "fill-extrusion-height": [
                        "case",
                        ["==", ["get", "id"], selectedBooth], 4,   // Full height for selected booth
                        ["==", ["get", "id"], hoveredBoothId], 2,  // Half height for hovered booth
                        1                                     // Default height
                    ],
                    
                    "fill-extrusion-base": 0,
                    "fill-extrusion-opacity": 1,
                },
            });
            
            
              
              map.addLayer({
                id: "booth-borders",
                type: "line",
                source: "booths",
                layout: {},
                paint: {
                  "line-color": "#888", // White color for the borders
                  "line-opacity": 0.5,
                  "line-width": 0.5, // Adjust the width for the border thickness
                }
              }, "booths-layer");

              const isValidImageUrl = (url) => /\.(jpeg|jpg|png|gif|webp)$/i.test(url);

              const fetchLogoImage = async (image) => {
                try {
                  if (image.startsWith("data:image")) {
                    // Handle base64-encoded images
                    const blob = await (await fetch(image)).blob();
                    return await createImageBitmap(blob);
                  } else {
                    // Handle external URLs
                    const response = await fetch(image);
                    if (!response.ok) throw new Error(`Failed to fetch image: ${response.statusText}`);
                    const blob = await response.blob();
                    return await createImageBitmap(blob);
                  }
                } catch (error) {
                  console.error(`Error processing image: ${image}`, error);
                  return null; // Skip default image as requested
                }
              };     
              
              const promises = stands.map(async (stand) => {
                const { id, image } = stand.properties;
                if (image) {
                  try {
                    const logoImage = await fetch(image)
                      .then(response => response.blob())
                      .then(blob => createImageBitmap(blob));
              
                    if (logoImage) {
                      map.addImage(`logo-${id}`, logoImage, { sdf: false });
                    }
                  } catch (error) {
                    console.error("Error loading logo for stand ${id}:", error);
                  }
                }
              });
              
              Promise.all(promises)
              .then(() => {
                console.log("All logos added. Adding logo layer...");
            
                // Check if the layer already exists to avoid duplicate addition
                if (!map.getLayer('booths-images')) {
                  map.addLayer({
                    id: 'booths-images',
                    type: 'symbol',
                    source: 'booths',
                    filter: ['has', 'image'], // Only include booths with images
                    layout: {
                      'icon-image': ['concat', 'logo-', ['get', 'id']], // Match icons by id
                      'icon-offset': [0, 0.6],
                      'icon-size': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        16, 0, // Hidden at zoom level 16 and below
                        17, [
                          'case',
                          ['==', ['get', 'exhibitor-type'], 'gold'], 0.05,
                          ['==', ['get', 'exhibitor-type'], 'silver'], 0.05,
                          ['==', ['get', 'exhibitor-type'], 'red'], 0.05,
                          0 // Default size if no match
                        ],
                        17.25, [
                          'case',
                          ['==', ['get', 'exhibitor-type'], 'gold'], 0.09,
                          ['==', ['get', 'exhibitor-type'], 'silver'], 0.05,
                          ['==', ['get', 'exhibitor-type'], 'red'], 0.05,
                          0
                        ],
                        18, [
                          'case',
                          ['==', ['get', 'exhibitor-type'], 'gold'], 0.09,
                          ['==', ['get', 'exhibitor-type'], 'silver'], 0.09,
                          ['==', ['get', 'exhibitor-type'], 'red'], 0.09,
                          0
                        ]
                      ],
                      'icon-anchor': 'center',
                      'icon-allow-overlap': true
                    },
                    paint: {
                      'icon-opacity': [
                        'interpolate',
                        ['linear'],
                        ['zoom'],
                        16, 0, // Hidden at zoom level 16 and below
                        17.25, [
                          'case',
                          ['==', ['get', 'exhibitor-type'], 'gold'], 1, // Gold visible at zoom >= 17.25
                          0 // Hidden otherwise
                        ],
                        18, [
                          'case',
                          ['==', ['get', 'exhibitor-type'], 'silver'], 1, // Silver visible at zoom >= 18
                          0
                        ],
                        19, 1 // Fully visible at zoom 19 and above
                      ]
                    }
                  });
                }
              })
              .catch((error) => {
                console.error("Error adding logo layer:", error);
              });
            
              
              const attemptedImages = new Set();
              map.on('styleimagemissing', async (e) => {
                const missingImageId = e.id;
                if (attemptedImages.has(missingImageId)) return;
                attemptedImages.add(missingImageId);
                const stand = stands.find(stand => `logo-${stand.properties.id}` === missingImageId);
                if (stand?.properties.image && isValidImageUrl(stand.properties.image)) {
                  try {
                    const logoImage = await fetchLogoImage(stand.properties.image);
                    if (logoImage) {
                      map.addImage(missingImageId, logoImage, { sdf: false });
                    }
                  } catch (error) {
                    console.error(`Error dynamically loading missing image: ${missingImageId}`, error);
                  }
                }
              });
        

           //Layer for the booth labels
              map.addLayer({
                id: "booth-labels",
                type: "symbol",
                source: "booths",
                layout: {
                  "text-field": ["get", "Name"], // Ensure this matches your GeoJSON properties
                  "text-size": 8,
                  "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
                  "text-offset": [0, 0.6],
                  "text-anchor": "top",
                },
                paint: {
                  "text-color": "#000000", // Use a distinct color like black for visibility
                  "text-halo-color": "#FFFFFF",
                  "text-halo-width": 1.5,
                  "text-halo-blur": 0.5,
                  "text-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    15,
                    0, // Hidden at zoom level 15 and below
                    16,
                    [
                      "case",
                      ["==", ["get", "category"], "gold"],
                      1, // Visible for gold category at zoom 16 and above
                      0
                    ],
                    17, 1  // Fully visible for all labels at zoom level 17 and above
                  ]
                }
              });

              map.addLayer({
                id:"hall-border",
                type:"line",
                source:"borderData",
                paint:{
                  "line-color":"grey",
                  "line-width":4,
                  "line-opacity":0.7,
                }
              },"booths-layer")

              map.addLayer({
                id:"hall-layer",
                type:"fill",
                source:"borderData",
                paint:{
                  "fill-color":"white"
                }
              },"booths-layer");

              map.on("click", "booths-layer", (e) => {
                // const coordinates = e.features[0].geometry.coordinates[0][0].slice();
                // const { Name, Description, id } = e.features[0].properties;

                // flyToBooth(map, coordinates, id);
                handleView(e.features[0], map);
              });
               
              
              map.on("mousemove", "booths-layer", (e) => {            
                const boothId = e.features[0].id || e.features[0].properties.id;
                if (boothId !== undefined && boothId !== 0) {
                  setHoveredBoothId(boothId);   
                 map.getCanvas().style.cursor = "pointer";
                }
                if (e.features.length > 0 && !isFilteredBoothActive && !isBoothSelected) {
          
                  const group = e.features[0].properties.group;
                  const hoverColor = lighterGroupColors[group] || "#D3D3D3"; // Default light grey
                  
                  // Update the hover color
                  map.setPaintProperty("booths-layer", "fill-extrusion-color", [
                      "case",
                      //["==", ["id"], selectedBoothId], hoverColor, // Highlight selected booth
                      ["==", ["id"], boothId], hoverColor,
                      ["==", ["get", "group"], "red"], "#FF6347",
                      ["==", ["get", "group"], "green"], "#32CD32",
                      ["==", ["get", "group"], "purple"], "#9370DB",
                      ["==", ["get", "group"], "orange"], "#FFA500",
                      ["==", ["get", "group"], "GreenYellow"], "#9ACD32",
                      ["==", ["get", "group"], "Coral"], "#FF4500",
                      ["==", ["get", "group"], "Gold"], "#FFD700",
                      "#ADFF2F" // Default color
                  ]);
                }
                
              });

              map.on("mouseleave", "booths-layer", (e) => {

                setHoveredBoothId(null);
                map.getCanvas().style.cursor = "";
          
                if(!isFilteredBoothActive && !isBoothSelected) {
                  // Reset the color
                  map.setPaintProperty("booths-layer", "fill-extrusion-color", [
                      "case",
                      ["==", ["get", "group"], "red"], "#FF6347",
                      ["==", ["get", "group"], "green"], "#32CD32",
                      ["==", ["get", "group"], "purple"], "#9370DB",
                      ["==", ["get", "group"], "orange"], "#FFA500",
                      ["==", ["get", "group"], "GreenYellow"], "#9ACD32",
                      ["==", ["get", "group"], "Coral"], "#FF4500",
                      ["==", ["get", "group"], "Gold"], "#FFD700",
                      "#ADFF2F" // Default color
                  ]);
                }          
              });

              let selectedBoothId = null;

              map.on("click", "booths-layer", (e) => {
                const boothId =  e.features[0].id || e.features[0].properties.id;
                 //setSelectedBooth(boothId);
                 isFilteredBoothActive = false;
                 if (selectedBoothId !== boothId) {
                  // Update selected booth ID
                  selectedBoothId = boothId;
              
                  const group = e.features[0].properties.group;
                  const hoverColor = lighterGroupColors[group] || "#D3D3D3"; // Default light grey
                  
              
                  // Apply color to the selected booth
                  map.setPaintProperty("booths-layer", "fill-extrusion-color", [
                    "case",
                    ["==", ["id"], selectedBoothId], hoverColor, // Highlight selected booth
                    ["==", ["get", "group"], "red"], "#FF6347",
                    ["==", ["get", "group"], "green"], "#32CD32",
                    ["==", ["get", "group"], "purple"], "#9370DB",
                    ["==", ["get", "group"], "orange"], "#FFA500",
                    ["==", ["get", "group"], "GreenYellow"], "#9ACD32",
                    ["==", ["get", "group"], "Coral"], "#FF6F61",
                    ["==", ["get", "group"], "Gold"], "#FFD700",
                    "#ADFF2F" // Default color
                  ]);
                }
              
              });

              Promise.all([
                fetch("/vertices3.geojson").then((response) => response.json()),
                fetch("/edges3.geojson").then((response) => response.json()),
              ]).then(([verticesData, edgesData]) => {
                setVerticesData( verticesData );
                setEdgesData( edgesData);
                //addGraphLayers(map, verticesData, edgesData);
              });

              setMap(map);
              
                
        });
        
        let renderer;
        if(exhibitiontreeData){

          exhibitiontreeData.features.forEach((feature) => {
            const coordinates = feature.geometry.coordinates;
            const scale = 0.7;
            const altitude = 0.0; // Adjust as needed to keep models grounded
            const rotation = [ Math.PI/2,0, 0];
           
            // Convert GeoJSON coordinates to Mercator coordinates
            const mercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
                { lng: coordinates[0], lat: coordinates[1] },
                altitude
            );
      
            const modelTransform = {
                translateX: mercatorCoordinate.x ,
                translateY: mercatorCoordinate.y,
                translateZ: mercatorCoordinate.z,
                rotateX: rotation[0],
                rotateY: rotation[1],
                rotateZ: rotation[2],
                scale: mercatorCoordinate.meterInMercatorCoordinateUnits() * scale,
            };
      
            const customLayer = {
                id: `3d-tree-${feature.id}`, // unique ID for each tree
                type: 'custom',
                renderingMode: '3d',
                onAdd: function (gl) {
                    this.camera = new THREE.Camera();
                    this.scene = new THREE.Scene();
      
                    // Add directional light
                    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
                    directionalLight.position.set(0, -70, 150).normalize();
                    this.scene.add(directionalLight);
  
                    const directionalLight2 = new THREE.DirectionalLight(0xffffff);
                    directionalLight2.position.set(0, 70, 100).normalize();
                    this.scene.add(directionalLight2);
      
                    const loader = new GLTFLoader();
                    loader.load('/tree.glb', (gltf) => {
                      gltf.scene.position.set(0, 0, 0); // Adjust Z for ground level
                      //gltf.scene.rotation.set(Math.PI / 2, 0, 0); // Adjust to align with Mapbox
                      this.scene.add(gltf.scene);
                    });
                },
                render: function (gl, matrix) {
                    const rotationX = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(1, 0, 0),
                        modelTransform.rotateX
                    );
                    const rotationY = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(0, 1, 0),
                        modelTransform.rotateY
                    );
                    const rotationZ = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(0, 0, 1),
                        modelTransform.rotateZ
                    );
      
                    const m = new THREE.Matrix4().fromArray(matrix);
                    const l = new THREE.Matrix4()
                        .makeTranslation(
                            modelTransform.translateX,
                            modelTransform.translateY,
                            modelTransform.translateZ
                        )
                        .scale(
                            new THREE.Vector3(
                                modelTransform.scale,
                                -modelTransform.scale,
                                modelTransform.scale
                            )
                        )
                        .multiply(rotationX)
                        .multiply(rotationY)
                        .multiply(rotationZ);
      
                    this.camera.projectionMatrix = m.multiply(l);
      
                    if(!renderer) {
                      renderer = new THREE.WebGLRenderer({
                        canvas: map.getCanvas(),
                        context: gl,
                        antialias: true,
                    });
                    }
                    
      
                    renderer.autoClear = false;
                    renderer.resetState();
                    renderer.render(this.scene, this.camera);
                    map.triggerRepaint();
                },
            };
      
            map.on('style.load', () => {
              map.addLayer(customLayer, 'waterway-label');
            });
           });
        }

       
        if (towerdata) {
          
          towerdata.features.forEach((feature) => {
        
              const coordinates = feature.geometry.coordinates;
              const scale = feature.scale; // Use feature's scale if available
              const altitude = feature.altitude;
              const rotation = feature.rotation ? [
                (feature.rotation.x * Math.PI) / 180,
                (feature.rotation.y * Math.PI) / 180,
                (feature.rotation.z * Math.PI) / 180
            ] : [Math.PI / 2, 0, 0];
            
              const mercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
                  { lng: coordinates[0], lat: coordinates[1] },
                  altitude
              );
      
              const modelTransform = {
                  translateX: mercatorCoordinate.x,
                  translateY: mercatorCoordinate.y,
                  translateZ: mercatorCoordinate.z,
                  rotateX: rotation[0],
                  rotateY: rotation[1],
                  rotateZ: rotation[2],
                  scale: mercatorCoordinate.meterInMercatorCoordinateUnits(),
              };
      
              const customLayer = {
                  id: `3d-tower-${feature.id}`,
                  type: 'custom',
                  renderingMode: '3d',
                  onAdd: function (gl) {
                      this.camera = new THREE.Camera();
                      this.scene = new THREE.Scene();
      
                      const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
                      directionalLight.position.set(0, -70, 150).normalize();
                      this.scene.add(directionalLight);
      
                      const directionalLight2 = new THREE.DirectionalLight(0xffffff);
                      directionalLight2.position.set(0, 70, 100).normalize();
                      this.scene.add(directionalLight2);
      
                      const loader = new GLTFLoader();
                      loader.load('/2towersv2 (1).glb', (gltf) => {
                          gltf.scene.position.set(0, 0, 0); 
                          this.scene.add(gltf.scene);
                      });
                  },
                  render: function (gl, matrix) {
                      const rotationX = new THREE.Matrix4().makeRotationAxis(
                          new THREE.Vector3(1, 0, 0),
                          modelTransform.rotateX
                      );
                      const rotationY = new THREE.Matrix4().makeRotationAxis(
                          new THREE.Vector3(0, 1, 0),
                          modelTransform.rotateY
                      );
                      const rotationZ = new THREE.Matrix4().makeRotationAxis(
                          new THREE.Vector3(0, 0, 1),
                          modelTransform.rotateZ
                      );
      
                      const m = new THREE.Matrix4().fromArray(matrix);
                      const l = new THREE.Matrix4()
                          .makeTranslation(
                              modelTransform.translateX,
                              modelTransform.translateY,
                              modelTransform.translateZ
                          )
                          .scale(
                              new THREE.Vector3(
                                  modelTransform.scale,
                                  -modelTransform.scale,
                                  modelTransform.scale
                              )
                          )
                          .multiply(rotationX)
                          .multiply(rotationY)
                          .multiply(rotationZ);
      
                      this.camera.projectionMatrix = m.multiply(l);
      
                      if (!renderer) {
                          renderer = new THREE.WebGLRenderer({
                              canvas: map.getCanvas(),
                              context: gl,
                              antialias: true,
                          });
                      }
      
                      renderer.autoClear = false;
                      renderer.resetState();
                      renderer.render(this.scene, this.camera);
                      map.triggerRepaint();
                  },
              };
      
              map.on('style.load', () => {
                  map.addLayer(customLayer, 'waterway-label');
              });
          });
      }
      
      if (parkingdata) {
        parkingdata.features.forEach((feature) => {
    
            const coordinates = feature.geometry.coordinates[0];    
            const scale = feature.scale; // Use feature's scale if available
            const altitude = feature.altitude;
            const rotation = feature.rotation ? [
                (feature.rotation.x * Math.PI) / 180,
                (feature.rotation.y * Math.PI) / 180,
                (feature.rotation.z * Math.PI) / 180
            ] : [Math.PI / 2, 0, 0];
    
            const mercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(
              { lng: coordinates[0], lat: coordinates[1] },
              altitude
            );
    
            const modelTransform = {
                translateX: mercatorCoordinate.x,
                translateY: mercatorCoordinate.y,
                translateZ: mercatorCoordinate.z,
                rotateX: rotation[0],
                rotateY: rotation[1],
                rotateZ: rotation[2],
                scale: mercatorCoordinate.meterInMercatorCoordinateUnits(),
            };
    
            const customLayer = {
                id: `3d-logo-${feature.id}`,
                type: 'custom',
                renderingMode: '3d',
                onAdd: function (gl) {
                    this.camera = new THREE.Camera();
                    this.scene = new THREE.Scene();
    
                    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8);
                    directionalLight.position.set(0, -70, 150).normalize();
                    this.scene.add(directionalLight);
    
                    const directionalLight2 = new THREE.DirectionalLight(0xffffff);
                    directionalLight2.position.set(0, 70, 100).normalize();
                    this.scene.add(directionalLight2);
    
                    const loader = new GLTFLoader();
                    loader.load('/bridge_parking.glb', (gltf) => {
                        gltf.scene.position.set(0, 0, 0); 
                        this.scene.add(gltf.scene);
                    });
                },
                render: function (gl, matrix) {
                    const rotationX = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(1, 0, 0),
                        modelTransform.rotateX
                    );
                    const rotationY = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(0, 1, 0),
                        modelTransform.rotateY
                    );
                    const rotationZ = new THREE.Matrix4().makeRotationAxis(
                        new THREE.Vector3(0, 0, 1),
                        modelTransform.rotateZ
                    );
    
                    const m = new THREE.Matrix4().fromArray(matrix);
                    const l = new THREE.Matrix4()
                        .makeTranslation(
                            modelTransform.translateX,
                            modelTransform.translateY,
                            modelTransform.translateZ
                        )
                        .scale(
                            new THREE.Vector3(
                                modelTransform.scale,
                                -modelTransform.scale,
                                modelTransform.scale
                            )
                        )
                        .multiply(rotationX)
                        .multiply(rotationY)
                        .multiply(rotationZ);
    
                    this.camera.projectionMatrix = m.multiply(l);
    
                    if (!renderer) {
                        renderer = new THREE.WebGLRenderer({
                            canvas: map.getCanvas(),
                            context: gl,
                            antialias: true,
                        });
                    }
    
                    renderer.autoClear = false;
                    renderer.resetState();
                    renderer.render(this.scene, this.camera);
                    map.triggerRepaint();
                },
            };
    
            map.on('style.load', () => {
                map.addLayer(customLayer, 'waterway-label');
            });
        });

        
    }
    
      
      

        // If this code is inside a function that requires a cleanup, use the cleanup function like this:
        return () => {
          map.remove()
        }
    
      };

      if (!map)
      { 
        initializeMap({ setMap, mapContainer });
      } else {
        // findBoothPath( booths[5], booths[35], booths, passagesData , poisData, map );
      } 
      
      if (!map || !slug) return;

      const findBoothBySlug = (slug) => {
        if (!map.getSource("booths")) return; // Ensure source exists
    
        // Query features from the Mapbox source layer
        const features = map.querySourceFeatures("booths", {
          sourceLayer: "booth-layer", // Your Mapbox source layer name
        });
    
        const foundBooth = features.find(
          (feature) => feature.properties.slug === slug
        );
    
        if (foundBooth) {
          isFilteredBoothActive = false;
          isBoothSelected = true;
          setSelectedCompany(foundBooth);
    
          // Fly to booth location
          const coordinates = foundBooth.geometry.coordinates;
          flyToCompany(map, coordinates, foundBooth.properties.id);
        }
      };
    
      setTimeout(() => {
      if (map.isStyleLoaded()) {
        map.once("style.load", () => findBoothBySlug(slug));
      } else {
        findBoothBySlug(slug);
      }
      }, 1000);
      
    }); 
  }
  }, [map, selectedBooth, loading,slug, map]);

  const flyToBooth = (map, coordinates, boothId) => {
    if (map) {
      map.flyTo({
        center: coordinates,
        duration: 10000, // Animate over 2 seconds
        essential: true,
        zoom: 18,
        pitch: 60,
        bearing: 0,
      });
    }
    setSelectedBooth(boothId);
  };

  const flyToCompany = (map, coordinates, boothId) => {
    if (boothId > 0) {
      const coord = convertToLngLat(coordinates);
      if (map) {
        map.flyTo({
          center: adjustCenterForMobile(coord[0], flyToViewState.bearing),
          duration: 1000, // Animate over 2 seconds
          essential: true,
          zoom: flyToViewState.zoom,
          pitch: flyToViewState.pitch,
          pitch_max: flyToViewState.pitch_max,
          pitch_min: flyToViewState.bearing.pitch_min,
          bearing: flyToViewState.bearing,
          padding:{bottom:90}
        });
      }
      setSelectedBooth(boothId);
    }
  };


  const handleBoothSelection = (boothId) => {
    setSelectedBooth(boothId);
    map.flyTo({
      center: booths.find((booth) => booth.id === boothId).geometry.coordinates[0][0],
      zoom: 23,
      pitch: 60,
      bearing: 120.2,
    });
  };

  const resetView = () => {
    // if (map) {
    //   map.flyTo({
    //     center: initialViewState.center,
    //     zoom: initialViewState.zoom,
    //     pitch: initialViewState.pitch,
    //     bearing: initialViewState.bearing,
    //     essential: true, // This animation is considered essential with respect to prefers-reduced-motion
    //   });
    // }
  };
  const getBoothPoi = (boothName) =>{
    const matchingFeatures = booths.filter(
      (feature) =>
        feature.properties?.Name &&
        feature.properties?.Name.toLowerCase().includes(boothName.toLowerCase())
    );
    if( matchingFeatures.length === 1 ) // exactly one booth found.
    {
      return matchingFeatures[0].properties.poi;
    }
    return null;
  };
  const calculatePath = (startPoint, endPoint, mode) =>{
    let startPoi = getBoothPoi( startPoint);
    let endPoi = getBoothPoi( endPoint );
    if( startPoi && endPoi )
      return addGraphLayers(map, verticesData, edgesData, startPoi, endPoi, mode);
    return [];// no path found
  };
  const menuItems = [
    { name: "Search", icon: <MagnifyingGlassIcon className="w-5 h-5" /> },
    { name: "Directions", icon: <MapPinIcon className="w-5 h-5" /> },
    { name: "Bookmarks", icon: <BookmarkIcon className="w-5 h-5" /> },
    { name: "Tasks", icon: <Bars3Icon className="w-5 h-5" /> },
    { name: "Help", icon: <QuestionMarkCircleIcon className="w-5 h-5" /> },
  ];
  return (
    <div style={{ position: "relative" }}>
      {loading ? (
        <SplashScreen />
      ) : (
        <div>
          <div style={{ position: "relative" }}>
            {isDesktop && (
              <DesktopSideBar
                menuItems={menuItems}
                handleMenuClick={handleMenuClick}
              />
            )}
            {/* Main Content */}
            <div
              ref={mapContainer}
              className="fixed z-5 top-0 left-0 h-full w-full"
            />
            <div
              className={` ${
                isMobile ? "left-4 h-[50%]" : "left-10 h-full"
              } z-2`}
              style={{
                pointerEvents: "none",
              }}
            >
              {activeItem === "Directions" &&
                !selectedCompany &&
                verticesData &&
                edgesData && (
                  <div
                    style={{
                      pointerEvents: "auto",
                    }}
                  >
                    <GetDirections
                      map={map}
                      isMobile={isMobile}
                      calculateCustomPath={calculatePath}
                      booths={booths}
                      fitMapToBooth={fitMapToBooth}
                      setActiveItem={setActiveItem}
                      ArrowLeftIcon={ArrowLeftIcon}
                      onBack={handleBack}
                      setFilteredBooths={setFilteredBooths}
                      setFilter={setFilter}
                    />
                  </div>
                )}

              {activeItem === "Search" && !selectedCompany && (
                <div style={{ pointerEvents: "auto" }}>
                  <SearchBar
                    booths={booths}
                    setFilteredBooths={setFilteredBooths}
                    setFilter={setFilter}
                    isMobile={isMobile}
                    isDesktop={isDesktop}
                  />
                </div>
              )}

              {!selectedCompany &&
                (activeItem === "Search" ||
                  (activeItem === "Tasks" && activeTask === "Sectors")) &&
                filteredBooths.length > 0 &&
                filteredBooths.length < booths.length && (
                  <div style={{ pointerEvents: "auto" }}>
                    <SearchResultsList
                      results={filteredBooths}
                      onView={handleView}
                      map={map}
                      toggleBookmark={toggleBookmark}
                      bookmarkedBooths={bookmarkedBooths}
                      isMobile={isMobile}
                    />
                  </div>
                )}

              {activeItem === "Bookmarks" && !selectedCompany && (
                <div
                  style={{
                    pointerEvents: "auto",
                    height: "80%", // Adjust this to 80%
                  }}
                >
                  <FavoritesList
                    favorites={bookmarkedBooths}
                    onView={handleView}
                    map={map}
                    toggleBookmark={toggleBookmark}
                    setBookmarksFilter={setBookmarksFilter}
                    isMobile={isMobile}
                    setActiveItem={setActiveItem}
                  />
                </div>
              )}

            {selectedCompany ? (
              isAdminMode ? (
                <div style={{ pointerEvents: "auto", height: "90vh" }}>
                  <EditCompanyDetailsForm
                    company={selectedCompany}
                    onBack={handleBack}
                    toggleBookmark={toggleBookmark}
                    isBookmarked={
                      bookmarkedBooths.some((b) => b.id === selectedCompany.id)
                    }
                    toggleVisited={toggleVisited}
                    isMobile={isMobile}
                    map={map}
                    setActiveItem={setActiveItem}
                    setFilter={setFilter}
                    setSelectedBooth={setSelectedBooth}
                    maineCoordinates={maineCoordinates}
                    selectedCategories={selectedCategories}
                    saveCompanyDetails={saveCompanyDetails}
                    setCompany={setCompany}
                    ArrowLeftIcon={ArrowLeftIcon}
                    BookmarkIconSolid={BookmarkIconSolid}
                    BookmarkIcon={BookmarkIcon}
                    ShareIcon={ShareIcon}
                    initialViewState={initialViewState}
                  />
                </div>
              ) : (
                <div style={{ pointerEvents: "auto", height: "90vh" }}>
                  <CompanyDetailsCard
                    company={selectedCompany}
                    onBack={handleBack}
                    toggleBookmark={toggleBookmark}
                    favorites={bookmarkedBooths}
                    isBookmarked={
                      bookmarkedBooths.some((b) => b.id === selectedCompany.id)
                    }
                    isVisited={
                      visitedBooths.includes(selectedCompany.id)
                    }
                    toggleVisited={toggleVisited}
                    isMobile={isMobile}
                    map={map}
                    calculateCustomPath={calculatePath}
                    booths={booths}
                    fitMapToBooth={fitMapToBooth}
                    setActiveItem={setActiveItem}
                    setFilteredBooths={setFilteredBooths}
                    setFilter={setFilter}
                    setSelectedBooth={setSelectedBooth}
                    maineCoordinates={maineCoordinates}
                    selectedCategories={selectedCategories}
                    saveCompanyDetails={saveCompanyDetails}
                    setCompany={setCompany}
                  />
                </div>
              )
            ) : (
              <div style={{ pointerEvents: "auto", height: "90vh" }}>
                <p>.</p>
              </div>
            )}


              {activeItem === "Tasks" &&
                activeTask === "Sectors" &&
                isCategorySelectorVisible && (
                  <div style={{ pointerEvents: "auto",height: "100vh"}}>
                    <CategorySelector
                      isMobile={isMobile}
                      categories={categories}
                      setFilter={setFilter}
                      filteredBooths={filteredBooths}
                      onView={handleView}
                      map={map}
                      toggleBookmark={toggleBookmark}
                      bookmarkedBooths={bookmarkedBooths}
                      closeCategorySelector={closeCategorySelector}
                      ArrowLeftIcon={ArrowLeftIcon}
                      setActiveItem={setActiveItem}
                      setSelectedCategories={setSelectedCategories}
                      selectedCategories={selectedCategories}
                    />
                  </div>
                )}

              {activeItem === "Tasks" && (
                <div style={{ pointerEvents: "auto" }}>
                  <DesktopTasksBar
                    isMobile={isMobile}
                    resetView={resetView}
                    setFilter={setFilter}
                    setActiveTask={setActiveTask}
                    setActiveItem={setActiveItem}
                    activeTask={activeTask}
                    setCategorySelectorVisible={setIsCategorySelectorVisible}
                    setIsAmenitiesVisible={setIsAmenitiesVisible}
                    setIsSupportTaskBarVisible={setIsSupportTaskBarVisible}
                    setStagesVisible={setIsStagesVisible}
                    setIsLoungesVisible={setIsLoungesVisible}
                    setGettingHereVisible={setIsGettingHereVisible}
                    map={map}
                  />
                </div>
              )}

              {isAmenitiesVisible && (
                <AmenitiesTaskBar
                  amenities={amenities}
                  setActiveAmenity={setActiveAmenity}
                />
              )}

              {isSupportTaskBarVisible && (
                <SupportTaskBar
                  supportCategories={supportCategories}
                  setActiveSupport={setActiveSupport}
                />
              )}

              {isStagesVisible && exhibitionData && (
                <div style={{ pointerEvents: "auto" }}>
                  <StagesComponent
                    stagesData={stages}
                    exhibitionData={exhibitionData}
                    map={map}
                    isMobile={isMobile}
                    ArrowLeftIcon={ArrowLeftIcon}
                    toggleBookmark={toggleBookmark}
                  />
                </div>
              )}
              {isGettingHereVisible && (
                <div style={{ pointerEvents: "auto" }}>
                  <GettingHereTaskBar
                    gettinghereTaskBarData={gettinghereTaskBar}
                    gettingHereData={gettinghere}
                    onBack={handleBack}
                    map={map}
                    isMobile={isMobile}
                    ArrowLeftIcon={ArrowLeftIcon}
                    setActiveTask = {setActiveTask}
                    originalBounds={originalBounds}
                    parkingdata={parkingdata}
                  />
                </div>
              )}
              {isLoungesVisible && (
                <LoungesSelector
                  loungesdata={lounges}
                  map={map}
                  isMobile={isMobile}
                  ArrowLeftIcon={ArrowLeftIcon}
                />
              )}

               {/* <DownloadLogos /> */}
            </div>
          </div>
        </div>
      )}
      <a href="https://xporience.com" target="blank">
        <div
          className={`flex items-start px-1 py-1 rounded-lg fixed ${
            isMobile ? "bottom-12 mr-1" : "bottom-2 mr-12"
          } right-2 bg-gray-100/50 backdrop-blur text-xxs text-gray-800 whitespace-nowrap z-[2] sm:bottom-4 sm:right-4 sm:bg-gray-300/40`}
        >
          Event map by <h3 className="text-red-600"> &nbsp;Xporience </h3>
        </div>
      </a>
      {isMobile && (
        <MobileSideBar
          menuItems={menuItems}
          handleMenuClick={handleMenuClick}
        />
      )}
    </div>
  );
  

};

// ===================================================================================//
// ===================================================================================//
const FavoritesList = ({ favorites, onView, map, toggleBookmark, isMobile, setBookmarksFilter, setActiveItem}) => {
  useEffect(() => {
    // Automatically highlight the bookmarked booths when the list is displayed
    setBookmarksFilter();
  }, [favorites]);

  const handleBackbutton = ()=>{
    setBookmarksFilter(null);
    setActiveItem("Search");
  }

  return (
    <div
        className={`fixed ${isMobile ? "h-[100vh]" : "top-10 left-16 h-[80%]"} bg-white bg-opacity-80 shadow rounded-lg p-3 overflow-y-auto z-20 flex flex-col custom-scrollbar`}
        style={{
          width: isMobile ? "100%" : "22rem", // Conditional width
        }}
      >
      <div className="flex items-center mb-4">
         <button
          onClick={handleBackbutton} // Replace 'onBack' with your back function
          className="p-1 rounded hover:bg-gray-200"
          aria-label="Go Back"
        >
          <ArrowLeftIcon className="w-4 h-4" />
        </button>
        <h2 className="text-base font-bold ml-2">My Favorites</h2>
      </div>

      {favorites.length > 0 ? (
        <div
        className={`flex-grow custom-scrollbar ${
          isMobile ? "pb-8" : " "
        }`}
      >
          {favorites.map((booth, index) => (
            <FavoriteCard key={index} onView={onView} booth={booth} map={map} toggleBookmark={toggleBookmark} isBookmarked={true} />
          ))}
        </div>
      ) : (
        <div className="flex-grow flex flex-col items-center justify-center text-gray-600">
          <BookmarkIcon className="w-12 h-12 mb-4 text-red-500" />
          <p className="text-center">No favorites added yet.</p>
          <p className="text-center">Click the bookmark icon on a booth to add it to your favorites.</p>
        </div>
      )}
    </div>
    
  );
};



// ===================================================================================//
// ===================================================================================//
const DesktopSideBar = ( { activeItem, menuItems, handleMenuClick } ) =>
{
return ( 
  <div className="fixed top-0 left-0 h-full w-12 bg-red z-10">
  <div className={`flex flex-col bg-slate-50 text-white h-full w-12 transition-width duration-300`}>
    <nav className="flex flex-col mt-10 space-y-2">
      {menuItems.map((item) => (
        <button
          key={item.name}
          className={`flex items-center px-4 py-2 text-sm font-medium ${activeItem === item.name ? "text-red-500" : "text-black"} hover:text-red-500 focus:outline-none`}
          onClick={() => handleMenuClick(item.name)}
        >
          <span>{item.icon}</span>
        </button>
      ))}
    </nav>
  </div>
</div>
  );
};

// ===================================================================================//
// ===================================================================================//
const MobileSideBar = ( { menuItems,activeItem, handleMenuClick } ) =>
  {
  return ( 
    <div
  className={`fixed bottom-0 w-full bg-white z-30 shadow rounded-lg`}
  style={{
    padding: "0.5rem",
  }}
>
  <div className={`flex flex-row bg-slate-50 text-black w-full transition-width duration-300`}>
    <nav className="flex flex-row justify-center space-x-2 w-full">
      {menuItems.map((item) => (
        <button
          key={item.name}
          className={`flex items-center px-4 py-2 text-sm font-medium ${activeItem === item.name ? "text-red-500" : "text-black"} hover:text-red-500 focus:outline-none`}
          onClick={() => handleMenuClick(item.name)}
        >
          <span>{item.icon}</span>
        </button>
      ))}
    </nav>
  </div>
</div>

    );
  };


// ===================================================================================//
// ===================================================================================//
  const AmenitiesTaskBar = ({ amenities, setActiveAmenity }) => {
    const amenitiesBarRef = useRef(null);
  
    const scrollAmenitiesBar = (direction) => {
      if (amenitiesBarRef.current) {
        const scrollAmount = 100; 
        if (direction === "left") {
          amenitiesBarRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
        } else if (direction === "right") {
          amenitiesBarRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
        }
      }
    };
  
    return (
      <div
        className="fixed left-16 top-[calc(5rem)] z-10 flex space-x-2 overflow-x-auto scrollbar-hide px-1 inset-x-0 z-[2] max-w-xl"
      >
        {/* Left Scroll Button */}
        <div
          className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
          onClick={() => scrollAmenitiesBar("left")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="w-5 h-5 heroicon-stroke-semibold"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
          </svg>
        </div>
  
        {/* Amenities Buttons */}
        <div
          className="flex space-x-2 overflow-x-auto scrollbar-hide"
          ref={amenitiesBarRef}
          style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
        >
          {amenities.map((amenity, index) => (
            <button
              key={index}
              onClick={() => setActiveAmenity(amenity)}
              className={`inline-flex px-2 py-1 rounded-lg text-sm leading-5 items-center whitespace-nowrap font-medium bg-gray-100 text-gray-500 border border-gray-300 hover:bg-gray-200`}
            >
              {amenity}
            </button>
          ))}
        </div>
  
        {/* Right Scroll Button */}
        <div
          className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
          onClick={() => scrollAmenitiesBar("right")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            aria-hidden="true"
            className="w-5 h-5 heroicon-stroke-semibold"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
          </svg>
        </div>
      </div>
    );
  };


// ===================================================================================//
// ===================================================================================//
function calculateBounds(coordinates, geometryType) {
  let minX = Infinity, minY = Infinity, maxX = -Infinity, maxY = -Infinity;

  if (geometryType === "MultiPolygon") {
      coordinates.forEach(polygon => {
          polygon[0]?.forEach(coord => {
              minX = Math.min(minX, coord[0]);
              minY = Math.min(minY, coord[1]);
              maxX = Math.max(maxX, coord[0]);
              maxY = Math.max(maxY, coord[1]);
          });
      });
  }

  if (minX === Infinity || minY === Infinity || maxX === -Infinity || maxY === -Infinity) {
      console.error("Invalid coordinates for bounds calculation");
      return [[0, 0], [0, 0]]; // Fallback bounds
  }

  return [[minX, minY], [maxX, maxY]]; // Return Southwest and Northeast corners
}

const DesktopTasksBar = ({ 
  isMobile, 
  resetView, 
  setActiveTask,
  setActiveItem, 
  activeTask, 
  setFilter, 
  setCategorySelectorVisible, 
  setIsAmenitiesVisible, 
  setIsSupportTaskBarVisible, 
  setStagesVisible, 
  setIsLoungesVisible, 
  setGettingHereVisible, // Added new setters for visibility
  map
}) => {

  // Updated tasks array with additional tasks
  const tasks = [
    "View All",
    "Sectors",
    "Getting Here",
    "Support",
    "Innovation Awards",
    "Amenities",
    "Stages",    // New task
    "Lounges",   // New task
  ];

  useEffect(() => {
    if (activeTask === "View All") {
      setCategorySelectorVisible(false);
      setIsSupportTaskBarVisible(false);
      setStagesVisible(false);
      setGettingHereVisible(false);
      setFilter([]);
      resetView();
    }
  }, [activeTask]);

  const handleTaskClick = (task) => {

    setActiveTask(task);

    setCategorySelectorVisible(false);
    setGettingHereVisible(false);
    setIsAmenitiesVisible(false);
    setIsSupportTaskBarVisible(false);
    setStagesVisible(false);  // Show Stages when clicked
    setIsLoungesVisible(false);
 
    if (task === "View All") {
      const targetName = task === "View All" ? "Takeover Stage" : " "; 
      const geojsonSource = map.getSource("booths"); // Replace with your GeoJSON source ID
      if (geojsonSource) {
        const features = geojsonSource._data.features; // Access the GeoJSON features
        const targetFeature = features.find(feature => feature.properties.Name === targetName);

        if (targetFeature && targetFeature.geometry && targetFeature.geometry.coordinates) {
            const geometryType = targetFeature.geometry.type;
            const coordinates = targetFeature.geometry.coordinates;

            let bounds;
            if (geometryType === "MultiPolygon") {
                bounds = calculateBounds(coordinates, geometryType); // Compute bounds safely
            } 

            const center = new mapboxgl.LngLatBounds(bounds[0], bounds[1]).getCenter(); // Get center

            map.flyTo({
              center: [center.lng, center.lat],
              padding: isMobile ? 20 : 40, // Optional: Padding around the bounds
              zoom: isMobile ? 14.5 : 16,
              duration: 800 ,// Animation duration in milliseconds
              pitch:40,
              bearing: 120
      });
     }
    }
    } else if (task === "Sectors") {
      setCategorySelectorVisible(true);
    } else if (task === "Getting Here") {
      setGettingHereVisible(true); // Show GettingHere when clicked
      const targetName = task === "Getting Here" ? "Takeover Stage" : " "; 
      const geojsonSource = map.getSource("booths"); // Replace with your GeoJSON source ID
      if (geojsonSource) {
        const features = geojsonSource._data.features; // Access the GeoJSON features
        const targetFeature = features.find(feature => feature.properties.Name === targetName);

        if (targetFeature && targetFeature.geometry && targetFeature.geometry.coordinates) {
            const geometryType = targetFeature.geometry.type;
            const coordinates = targetFeature.geometry.coordinates;

            let bounds;
            if (geometryType === "MultiPolygon") {
                bounds = calculateBounds(coordinates, geometryType); // Compute bounds safely
            } 

            const center = new mapboxgl.LngLatBounds(bounds[0], bounds[1]).getCenter(); // Get center

            map.flyTo({
                center: [center.lng, center.lat], // Fly to the calculated center
                zoom: isMobile ? 14.5 : 15.5,
                padding: isMobile ? 20 : { top: 20, bottom: 20, left: 500 },
                bearing: 120,
                pitch: 40,
                duration: 800
            });
        } 
    }

      if(isMobile){
        setActiveItem("");
      }
    } else if (task === "Amenities") {
      setIsAmenitiesVisible(true);
    } else if (task === "Support") {
      setIsSupportTaskBarVisible(true);
  } else if (task === "Stages") {
      setStagesVisible(true); // Show Stages when clicked
      const targetName = task === "Stages" ? "Takeover Stage" : " "; 
      const geojsonSource = map.getSource("booths"); // Replace with your GeoJSON source ID
      if (geojsonSource) {
        const features = geojsonSource._data.features; // Access the GeoJSON features
        const targetFeature = features.find(feature => feature.properties.Name === targetName);

        if (targetFeature && targetFeature.geometry && targetFeature.geometry.coordinates) {
            const geometryType = targetFeature.geometry.type;
            const coordinates = targetFeature.geometry.coordinates;

            let bounds;
            if (geometryType === "MultiPolygon") {
                bounds = calculateBounds(coordinates, geometryType); // Compute bounds safely
            } 

            const center = new mapboxgl.LngLatBounds(bounds[0], bounds[1]).getCenter(); // Get center

            map.flyTo({
                center: [center.lng, center.lat], // Fly to the calculated center
                zoom: isMobile ? 14.5 : 15,
                padding: isMobile ? 20 : { top: 20, bottom: 20, left: 500 },
                bearing: 120,
                pitch: 40,
                duration: 800
            });
        } 
    }    } else if (task === "Lounges") {
      setIsLoungesVisible(true); // Show Lounges when clicked
    } 
  };

  const taskBarRef = useRef(null);

  const scrollTaskBar = (direction) => {
    if (taskBarRef.current) {
      const scrollAmount = 100;
      if (direction === "left") {
        taskBarRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
      } else if (direction === "right") {
        taskBarRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
      }
    }
  };

  return (
    <div
      className={`fixed ${isMobile ? "top-7" : "top-4 sm:left-16"} left-1 z-10 pr-10 flex space-x-2 overflow-x-auto scrollbar-hide px-1 sm:px-0 inset-x-0 z-[2] max-w-xl`}
    >
      {/* Left Scroll Button */}
      <div
        className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
        onClick={() => scrollTaskBar("left")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          aria-hidden="true"
          className="w-5 h-5 heroicon-stroke-semibold"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5"></path>
        </svg>
      </div>
      
      {/* Task Buttons */}
      <div
        className="flex space-x-2 overflow-x-auto scrollbar-hide"
        ref={taskBarRef}
        style={{ maxWidth: "100%", whiteSpace: "nowrap" }}
      >
        {tasks.map((task, index) => (
          <button
            key={index}
            onClick={() => handleTaskClick(task)}
            className={`inline-flex px-2 py-1 rounded-lg mr-2 text-sm leading-5 items-center whitespace-nowrap font-medium ${task === activeTask ? "bg-gradient-to-r from-[#d93953] to-[#f48432] text-white" : "bg-gray-100 text-gray-500 border border-gray-300 hover:bg-gray-200"}`}
            style={{
              height: "100%",
              marginLeft: "0.25rem",
              paddingLeft: "1rem",
              paddingRight: "1rem"
            }}
          >
            {task.replace(/"/g, "")}
          </button>
        ))}
      </div>
      
      {/* Right Scroll Button */}
      <div
        className="shadow-md rounded-full border p-1 cursor-pointer transition duration-200 bg-gradient-to-r from-[#d93953] to-[#f48432] text-white hover:bg-[#d93953] z-4 flex items-center justify-center"
        onClick={() => scrollTaskBar("right")}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          aria-hidden="true"
          className="w-5 h-5 heroicon-stroke-semibold"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5"></path>
        </svg>
      </div>
    </div>
  );
};


const saveCompanyDetails = async (updatedDetails,setCompany) => {
  console.log("Saving company details locally:", updatedDetails);

  // Update the local state or context with the updated details
  // For example:
  setCompany((prevCompany) => ({
    ...prevCompany,
    properties: {
      ...prevCompany.properties,
      ...updatedDetails,
    },
  }));

  // update the data at server
  try {
    const response = await fetch("http://localhost:3000/update-company", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(updatedDetails),
    });

    if (response.ok) {
      alert("Data submitted successfully!");
    } else {
      alert("Failed to submit data.");
    }
  } catch (error) {
    console.error("Error submitting form data:", error);
  }

  alert("Company details saved successfully!");
};

// ===================================================================================//
// ===================================================================================//
const CompanyDetailsCard = ({
  company,
  onBack,
  toggleBookmark,
  isBookmarked,
  isVisited,
  toggleVisited,
  isMobile,
  map,
  calculateCustomPath,
  booths,
  fitMapToBooth,
  setActiveItem,
  setFilteredBooths,
  setFilter,
  setSelectedBooth,
  maineCoordinates,selectedCategories
}) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [showDirections, setShowDirections] = useState(false);

  const handleDirectionClick = () => {
    setShowDirections(true); // Show the GetDirections component
    setIsVisible(false); // Hide the main card and buttons
    setActiveItem("")
  };

  useEffect(() => {
    // Trigger the animation slightly after rendering
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay in milliseconds
    return () => clearTimeout(timeout); // Cleanup timeout
  }, []);

  // Reset showFullDescription when the company prop changes
  useEffect(() => {
    setShowFullDescription(false);
  }, [company]);

  const handleReset = () => {
    initialViewState.center = maineCoordinates;
    if (map) {
      map.flyTo({
        center: initialViewState.center,
        zoom: isMobile ? 17 : initialViewState.zoom,
        pitch: initialViewState.pitch,
        bearing: initialViewState.bearing,
      });
    }
     
    setFilter(selectedCategories);
    setSelectedBooth(null);
    onBack();
  };

  const handleShowFullDescription = () => {
    setShowFullDescription(true); // Simply show the full description
  };

  const description = company.properties.Description ? company.properties.Description : "";
  const truncatedDescription =
    description.length > 64 ? description.substring(0, 64) + "..." : description;

    const handleShare = () => {
      const siteName = window.location.origin; // Get website base URL
      const boothSlug = company.properties.slug; // Get company slug
      const title = company.properties.Name;
      const url = `${siteName}/${boothSlug}`; // Create full URL
    
      if (navigator.share) {
        navigator
          .share({
            title: title,
            url: url,
          })
          .then(() => console.log("Thanks for sharing!"))
          .catch(console.error);
      } else {
        // Fallback if Web Share API is not supported
        alert(`Share this link: ${url}`);
      }
    };    
    
    

  return (
    <>
      {showDirections && company?.properties?.Name ? (
        <GetDirections
          map={map}
          isMobile={isMobile}
          calculateCustomPath={calculateCustomPath}
          booths={booths}
          fitMapToBooth={fitMapToBooth}
          setActiveItem={setActiveItem}
          ArrowLeftIcon={ArrowLeftIcon}
          setShowDirections={setShowDirections} // Pass callback to toggle directions
          setShowCompanyDetails={setIsVisible} // Pass callback to restore company card
          destination={company.properties.Name}
          onBack={onBack}
          setFilteredBooths={setFilteredBooths}
          setFilter={setFilter}
        />
      ) : (
        <div
          className={`absolute shadow-2xl top-16 ${isMobile ? 'h-[60%]' : 'h-[80%]'} bg-white bg-opacity-98 rounded-lg p-4 z-50  ${isMobile ? 'w-[98%]' : 'w-80' } flex flex-col  
          ${isVisible ? "slide-in" : "initial-state"}`}
          style={{
            marginLeft: isMobile ? "0.25rem" : "4rem",
            marginTop: isMobile ? "17rem" : "0rem"
          }}
        >
          {/* Header Section */}
          <div className="flex items-center mb-4">
            <button
              onClick={handleReset} // Replace 'onBack' with your back function
               className="p-2 bg-gray-200 rounded-full hover:bg-gray-300"
            >
              <ArrowLeftIcon className="w-5 h-5 text-gray-500" />
            </button>
            <h2 className="text-xl font-semibold ml-8">Company Details</h2>

             {/* Bookmark and Share Buttons */}
          <div className="flex justify-end space-x-2 ml-4 flex-none">
            <button
              className="p-2 bg-gray-200 rounded-full"
              onClick={() => toggleBookmark(company)}
            >
              {isBookmarked ? (
                <BookmarkIconSolid className="w-5 h-5 text-red-500" />
              ) : (
                <BookmarkIcon className="w-5 h-5 text-gray-500" />
              )}
            </button>
            <button className="p-2 bg-gray-200 rounded-full" onClick={handleShare}>
              <ShareIcon className="w-5 h-5 text-gray-600" />
            </button>
          </div>
          </div>

          {/* Scrollable Content */}
          <div className="pl-2 pr-1 custom-scrollbar flex-col"
            style={{
              overflowY: "auto",
              maxHeight: isMobile ? "80vh" : "70vh", // Set maximum height for desktop and mobile
            }}>
            <img
              src={company.properties.image}
              alt={`${company.properties.Name} Logo`}
              className="h-32 object-contain mb-4 mx-auto"
            />
            <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
              {company.properties.Name}{" "}
              <span className="text-sm text-red-500">
                {company.properties.sponsorType}
              </span>
            </h3>
            <p className="text-gray-400 mt-1 flex items-center">
              <MapPinIcon className="w-4 h-4 mr-1" />
              Booth {company.properties.boothDetails}
            </p>

            {/* Centered and Wider Buttons */}
            <button
              className="w-3/5 mx-auto flex items-center justify-center text-white bg-black px-3 py-2 rounded-full my-2"
              onClick={handleDirectionClick}
            >
              <MapPinIcon className="w-4 h-4 mr-1" />
              Direction
            </button>
            <button
              className={`w-3/5 mx-auto flex items-center justify-center px-3 py-2 rounded-full my-2 ${
                isVisited
                  ? "text-white bg-red-500"
                  : "text-red-500 border border-red-500"
              }`}
              onClick={() => toggleVisited(company.id)}
            >
              Mark as visited
            </button>

            <p className="text-sm text-gray-600 my-4">
              {showFullDescription ? description : truncatedDescription}
            </p>
            {!showFullDescription && (
              <button
                className="text-xs text-blue-500"
                onClick={handleShowFullDescription}
              >
                + Show full Description
              </button>
            )}
            <div className="flex space-x-2 mt-4">
              <a
                href={
                  company.properties.website
                    ? company.properties.website.startsWith("http")
                      ? company.properties.website
                      : `https://${company.properties.website}`
                    : ""
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <GlobeAltIcon className="w-4 h-4 mr-1" />
              </a>
             {company.properties.linkedin && (
              <a
                href={
                  company.properties.linkedin
                    ? company.properties.linkedin.startsWith("http")
                      ? company.properties.linkedin
                      : `https://${company.properties.linkedin}`
                    : ""
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <GlobeAltIcon className="w-4 h-4 mr-1" />
              </a>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Animation Styles */}
      <style>
        {`
          .initial-state {
            transform: translateY(10%); /* Start slightly below */
            opacity: 0;
          }
          .slide-in {
            transform: translateY(0); /* End at original position */
            opacity: 1;
            transition: transform 0.3s ease-out, opacity 0.3s ease-out;
          }
        `}
      </style>
      {isVisible && (
        <div
          className={`${
            isMobile
              ? "fixed top-60 left-5 flex space-x-4"
              : "absolute left-[390px] top-16 flex flex-col space-y-2"
          }`}
        >
        </div>
      )}
    </>
  );
};





// ===================================================================================//
// ===================================================================================//

const SearchBar = ({ booths, setFilteredBooths, isMobile, setFilter,isDesktop }) => {
  const [query, setQuery] = useState("");
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    if (query) {
      const matchingFeatures = booths.filter((feature) =>
        feature.properties?.Name &&
        feature.properties?.Name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredBooths(matchingFeatures);
      setFilter(matchingFeatures);
    } else {
      setFilteredBooths([]);
      setFilter([]);
    }
  }, [query, booths, setFilteredBooths]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div
      className="fixed top-4"
      style={{ marginLeft: `${isMobile ? "1rem" : "4rem"}` }}
    >
      <div
        className="relative"
        style={{
          width:isMobile
              ? "84vw" // Mobile in portrait mode
              : "23.7rem", // Desktop (both landscape and portrait)
        }}
      >
        <MagnifyingGlassIcon className="absolute left-3 top-2.5 w-5 h-5 text-gray-500" />
        <input
          type="text"
          placeholder="Search..."
          value={query}
          onChange={handleInputChange}
          className={`pl-10 w-full px-4 text-sm border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-red-300 ${
            isMobile ? "py-2" : "py-2"
          }`}
        />

        {showResults && (
          <div className="absolute left-0 w-full bg-white shadow-lg rounded-lg mt-2 max-h-60 overflow-y-auto">
            <div className="p-2 border-b border-gray-200">
              <h4 className="text-xs font-semibold text-gray-500">PLACES</h4>
              {booths
                .filter((booth) =>
                  booth.properties?.Name.toLowerCase().includes(
                    query.toLowerCase()
                  )
                )
                .map((booth, index) => (
                  <div
                    key={index}
                    className="py-2 px-4 hover:bg-gray-100 cursor-pointer"
                  >
                    {booth.properties.Name}
                  </div>
                ))}
            </div>
            <div className="p-2">
              <h4 className="text-xs font-semibold text-gray-500">
                CATEGORIES
              </h4>
              <div className="py-2 px-4 hover:bg-gray-100 cursor-pointer">
                Example Category 1
              </div>
              <div className="py-2 px-4 hover:bg-gray-100 cursor-pointer">
                Example Category 2
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};


// ===================================================================================//

// ===================================================================================//
const SearchResultsList = ({ results, onView, map, toggleBookmark, bookmarkedBooths, isMobile, isListVieDef = isMobile ? false : true }) => {
  const [isListView, setIsListView] = useState(isListVieDef);

  const toggleListView = () => {
    setIsListView((prev) => !prev);
  };

  return (
    <div>
      <div
        className={`fixed ${
          isMobile
            ? isListView
              ? "flex-col"
              : "top-20 flex-row"
            : "top-16 flex-col pl-5 pr-20"
        } rounded-lg z-10 size-full`}
        style={{
          marginLeft: `calc((${isMobile ? 0 : 0.65})* (3rem + 1rem))`,
          height: `${isMobile ? (isListView ? 50 : 18.5) : 25}rem`,
          width: `${isMobile ? "100vw" : "30rem"}`, // Full width on mobile in ListView
        }}
      >
        <div className="p-4 bg-blur z-50"
         style={{backgroundColor:isMobile ? isListView? "rgba(255, 255, 255, 0.8)":"transparent" :"rgba(255, 255, 255, 0.8)"}}>
          {/* Back Button and Places Title Container in List View */}
          {isMobile && isListView && (
            <div
              className="flex items-center justify-start mb-2 w-full" // Ensure full width for List View
              style={{
                position: "sticky",
                top: 0,
                zIndex: 50,
                backgroundColor: "white",
                paddingBottom: "1rem",
              }}
            >
              <button
                className="bg-blue-100 text-blue-600 px-4 py-2 rounded-lg shadow-md flex items-center space-x-2 border border-blue-400 hover:bg-blue-200"
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={toggleListView}
              >
                <span style={{ fontSize: "1.2rem", lineHeight: "1" }}>
                  <ArrowLeftIcon className="w-4 h-4" />
                </span>
                <span>Back</span>
              </button>
              <h3 className="ml-4 text-lg font-semibold">Places</h3>
            </div>
          )}

          {/* Main container for search results */}
          <div
            className={`custom-scrollbar flex ${
              isMobile ? (isListView ? "flex-col" : "flex-row") : "flex-col"
            }`}
            style={{
              overflowY: "auto",
              overflowX: isListView ? "hidden" : "auto",
              maxHeight: isMobile ? "84vh" : "70vh",
              gap: "0.5rem",
              boxSizing: "border-box",
              flexGrow: 1,
              //paddingBottom: isListView ? "2rem" : " ", // Add paddingBottom for spacing
            }}
          >
            {results.length > 0 && results.map ? (
              results.map((booth, index) => (
                <FavoriteCard
                  key={index}
                  booth={booth}
                  map={map}
                  onView={onView}
                  toggleBookmark={toggleBookmark}
                  isMobile={isMobile}
                  isListView={isListView}
                  isBookmarked={bookmarkedBooths.some((b) => b.id === booth.id)}
                />
              ))
            ) : (
              <div className="flex-grow flex flex-col items-center justify-center text-gray-600">
                <p className="text-center">No results found.</p>
              </div>
            )}
          </div>

          {/* Toggle View Button for Mobile */}
          {isMobile && (
            <div
              className="flex justify-end items-center mt-4"
              style={{
                position: "sticky",
                bottom: 0,
                zIndex: 50,
              }}
            >
              <button
                className="bg-blue-100 text-blue-600 px-4 py-2 rounded-lg shadow-md flex items-center space-x-2 border border-blue-400 hover:bg-blue-200"
                style={{
                  fontSize: "0.9rem",
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={toggleListView}
              >
                <span style={{ fontSize: "1.2rem", lineHeight: "1" }}>≡</span>{" "}
                <span>List View</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};




export default App;
