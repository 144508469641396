import React, { useEffect, useState } from "react";

const EditCompanyDetailsForm = ({
  maineCoordinates,
  company,
  onBack,
  toggleBookmark,
  selectedCategories,
  saveCompanyDetails, // Callback to save the updated company details
  setCompany,
  isBookmarked,
  isVisited,
  toggleVisited,
  isMobile,
  ArrowLeftIcon,
  BookmarkIconSolid,
  BookmarkIcon,
  ShareIcon,
  setFilter,
  setSelectedBooth,
  map,
  initialViewState,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [editForm, setEditForm] = useState({
    id: company.properties.id,
    Name: company.properties.Name,
    Description: company.properties.Description,
    boothDetails: company.properties.boothDetails,
    image: company.properties.image,
    website: company.properties.website,
    linkedin: company.properties.linkedin,
  });

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setEditForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleSave = () => {
    // Save the updated details (pass to a parent function or send to server)
    saveCompanyDetails(editForm, setCompany); // Pass updated details to the save function
  };

  const handleReset = () => {
    initialViewState.center = maineCoordinates;
    if (map) {
      map.flyTo({
        center: initialViewState.center,
        zoom: isMobile ? 17 : initialViewState.zoom,
        pitch: initialViewState.pitch,
        bearing: initialViewState.bearing,
      });
    }

    setFilter(selectedCategories);
    setSelectedBooth(null);
    onBack();
  };

  const handleShare = () => {
    const siteName = window.location.origin;
    const url = siteName;
    const title = company.properties.Name;

    if (navigator.share) {
      navigator
        .share({
          title: title,
          url: url,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      alert(`Share this link: ${url}`);
    }
  };

  return (
    <>
      <div
        className={`absolute shadow-2xl top-16 ${
          isMobile ? "h-[60%]" : "h-[80%]"
        } bg-white bg-opacity-98 rounded-lg p-4 z-50  ${
          isMobile ? "w-[98%]" : "w-80"
        } flex flex-col  
          ${isVisible ? "slide-in" : "initial-state"}`}
        style={{
          marginLeft: isMobile ? "0.25rem" : "4rem",
          marginTop: isMobile ? "17rem" : "0rem",
        }}
      >
        {/* Header Section */}
        <div className="flex items-center mb-4">
          <button
            onClick={handleReset}
            className="p-2 bg-gray-200 rounded-full hover:bg-gray-300"
          >
            <ArrowLeftIcon className="w-5 h-5 text-gray-500" />
          </button>
          <h2 className="text-xl font-semibold ml-8">Edit Company Details</h2>

          {/* Bookmark and Share Buttons */}
          <div className="flex justify-end space-x-2 ml-4 flex-none">
            <button
              className="p-2 bg-gray-200 rounded-full"
              onClick={() => toggleBookmark(company)}
            >
              {isBookmarked ? (
                <BookmarkIconSolid className="w-5 h-5 text-red-500" />
              ) : (
                <BookmarkIcon className="w-5 h-5 text-gray-500" />
              )}
            </button>
            <button
              className="p-2 bg-gray-200 rounded-full"
              onClick={handleShare}
            >
              <ShareIcon className="w-5 h-5 text-gray-600" />
            </button>
          </div>
        </div>
          <div
            className="pl-2 pr-1 custom-scrollbar flex-col"
            style={{
              overflowY: "auto",
              maxHeight: isMobile ? "80vh" : "70vh", // Set maximum height for desktop and mobile
            }}
          >
            <form className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  name="Name"
                  value={editForm.Name}
                  onChange={handleFormChange}
                  className="w-full border-gray-400 rounded-md shadow-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  name="Description"
                  value={editForm.Description}
                  onChange={handleFormChange}
                  className="w-full border-gray-300 rounded-md shadow-sm"
               
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Booth Details
                </label>
                <input
                  type="text"
                  name="boothDetails"
                  value={editForm.boothDetails}
                  onChange={handleFormChange}
                  className="w-full border-gray-300 rounded-md shadow-sm"
                 
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Logo URL
                </label>
                <input
                  type="text"
                  name="image"
                  value={editForm.image}
                  onChange={handleFormChange}
                  className="w-full border-gray-300 rounded-md shadow-sm"
                
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Website
                </label>
                <input
                  type="text"
                  name="website"
                  value={editForm.website}
                  onChange={handleFormChange}
                  className="w-full border-gray-300 rounded-md shadow-sm"
                
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  LinkedIn
                </label>
                <input
                  type="text"
                  name="linkedin"
                  value={editForm.linkedin}
                  onChange={handleFormChange}
                  className="w-full border-gray-300 rounded-md shadow-sm"
                  
                />
              </div>
              <button
                type="button"
                onClick={handleSave}
                className="w-full text-white bg-blue-500 px-3 py-2 rounded-md"
              >
                Save Changes
              </button>
            </form>
          </div>
      </div>
    </>
  );
};

export default EditCompanyDetailsForm;
